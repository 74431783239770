
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, NavBar, Sticky } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [Sticky.name]: Sticky,
  },
})
export default class RankNavBar extends Mixins(Mixin) {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public status!: string;
  @Prop({ default: false }) public hasshare!: boolean;
  @Prop({ default: "" }) public rankType!: string;
  // rankType 1 为榜单广场navbar
  isFixed = false;
  get RankColor(): string {
    return this.isFixed == true ? "#fff" : "transparent";
  }
  get titleChange(): string {
    if (this.isFixed == true) {
      return this.title;
    } else {
      return "";
    }
  }
  onClickLeft(): void {
    if (this.rankType == "1") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  scrollHandles(data: { scrollTop: number; isFixed: true }): void {
    this.isFixed = data.isFixed;
  }
  share(): void {
    let url = `${location.origin}${location.pathname}#/rank-list?cityName=${this.LocationModule.cityName}&cityCode=${this.LocationModule.cityCode}`;
    let title = "演出爱好者的观演榜单";
    let desc = "热门演出先知晓，榜单广场随心挑，实时更新，想看就看！";
    let picUrl = "https://cdn.polyt.cn/activity/2022-06-07/Rank/rankShare.png";
    if (this.rankType != "1") {
      let detailId = this.$route.params.detailId;
      let rankIndex = this.$route.params.rankIndex;
      url = `${location.origin}${location.pathname}#/rank-detail/${detailId}/${rankIndex}`;
    }
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${desc}%&@,picUrl%&@/${picUrl}%&@,url%&@/${url}`
    );
  }
}
